<template>
         <v-card>
        <v-card-title class="primary">
        <v-btn flat text to="/register"> 
            <v-icon>
                mdi-chevron-left
            </v-icon>
        </v-btn> <v-spacer></v-spacer> Terms and Agreement Policy<v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
            <br/>
         <h4>Data and Account Registration Policy</h4>
<p>
This policy describes the information we process to support the services offered of the website and other products and features offered by  MAIS-FARMS website through MAIS-FARMS  LGU’s Admin-Server.

The clients may view the web anytime but should register first in case he intends to subscribe or transact business. His application will be processed and validated within 24 hrs. Once approved he can continue to complete the data needed and may transact business. 
</p>
<h4>What information do we collect?</h4>
<p>
There are two features of the system: First feature, for LGU’s Admin Server, we collect data/information such as your Complete Name, Residence Address, Farm Address, Contact Information, Date of Birth, Age, Farm data (Crops Planted, Poultry and Livestock Raised, Fishery Products and Processed Products) Product data (Farm and Processed products) and Organizational Affiliations, intended for LGU’s Admin-server feature of the web such as reports that are linked to the higher offices as required, specifically weather calamities or disturbances (typhoons, draught, pest infestations, and flooding) and for, analysis and statistical purposes, second is the eCommerce feature for marketing linkages of products available for sale. In the eCommerce feature, we process information about you and we only publish in the web your name, address, contact information and real-time products available for market.
</p>
<h4>Protection of Personal Information</h4>
<p>
MAIS-FARMS website takes the security of your personal information very seriously. The online services such product marketing is also protected with a sales agreement between farmer and the buyer. A pro-forma sales agreement is available for producer and buyer convenience. Information from the administrative reporting is protected in the Local Administration Servers of every Municipality and City. The personal information during online transit of information from the Local Server to the cloud server will be encrypted to avoid an online breach.
</p>

<h4>User Commitment </h4>
<p>
The user upon registration shall agree with the rules and regulations of the website in order to be officially registered. 

Once registered, the users (farmer, buyer & organization) shall comply with laws pertaining to data privacy and shall avoid encoding invalid data entries and fictitious transactions on the website.

The user account will be terminated once found encoding false data entries in his personal account as it will affect the accuracy of the system.
</p>
        </v-card-text>
        </v-card>
</template>

<script>
    export default{
        name: "TermsAgreementPolicy",

        
    }
</script>